<template>
  <div>
    <HeroCurved :heading="$t('writing_your_brief.title')"/>

    <div class="main-container text-left">
      <div class="container-fluid">
        <div class="d-flex flex-row pt-0 pt-md-3 mt-0 mb-0 mb-md-3">
          <b-col order-md="2" class="text-center col-12 col-md-4 p-0 overflow-hidden">
            <img class="rounded-lg img-small" :src="require('@/assets/WritingYourBrief/how-to-write-a-great-brief.jpg')"/>
          </b-col>
          <b-col order-md="1" class="col-12 col-md-8 d-flex flex-column align-items-start pr-3 pt-3 pt-md-0 pr-md-5 px-4 px-md-0">
            <h3 class="text-left font-weight-bold w-100">{{ $t('writing_your_brief.how_to_write_a_great_brief.title') }}</h3>
            <p>{{ $t('writing_your_brief.how_to_write_a_great_brief.p1') }}</p>
            <p>{{ $t('writing_your_brief.how_to_write_a_great_brief.p2') }}</p>
            <p>{{ $t('writing_your_brief.how_to_write_a_great_brief.p3') }}</p>
          </b-col>
        </div>

        <div class="d-flex flex-row pt-0 pt-md-3 mt-0 mb-0 mb-md-3">
          <b-col class="text-center col-12 col-md-4 p-0 overflow-hidden">
            <img class="rounded-lg" :src="require('@/assets/WritingYourBrief/what-is-a-brief.jpg')" />
          </b-col>
          <b-col class="col-12 col-md-8 d-flex flex-column align-items-start pl-3 pl-md-5 mt-4 m-md-0 px-4 px-md-0">
            <h3 class="text-left font-weight-bold w-100">{{ $t('writing_your_brief.what_is_a_brief.title') }}</h3>
            <p>{{ $t('writing_your_brief.what_is_a_brief.p1') }}</p>
            <p>{{ $t('writing_your_brief.what_is_a_brief.p2') }}</p>
            <ul>
              <li>{{ $t('writing_your_brief.what_is_a_brief.point1') }}</li>
              <li>{{ $t('writing_your_brief.what_is_a_brief.point2') }}</li>
              <li>{{ $t('writing_your_brief.what_is_a_brief.point3') }}</li>
              <li>{{ $t('writing_your_brief.what_is_a_brief.point4') }}</li>
              <li>{{ $t('writing_your_brief.what_is_a_brief.point5') }}</li>
              <li>{{ $t('writing_your_brief.what_is_a_brief.point6') }}</li>
              <li>{{ $t('writing_your_brief.what_is_a_brief.point7') }}</li>
            </ul>
          </b-col>
          <div>
            <p>{{ $t('writing_your_brief.what_is_a_brief.p3') }}</p>
          </div>
        </div>

        <div class="d-flex flex-row pt-0 pt-md-3 mt-0 mb-0 mb-md-3">
          <b-col order-md="2" class="text-center col-12 col-md-4 p-0 overflow-hidden">
            <img class="rounded-lg img-small" :src="require('@/assets/WritingYourBrief/what-are-the-benefits-of-a-brief.jpg')" />
          </b-col>
          <b-col order-md="1" class="col-12 col-md-8 d-flex flex-column align-items-start pr-3 pt-3 pt-md-0 pr-md-5 px-4 px-md-0">
            <h3 class="text-left font-weight-bold w-100">{{ $t('writing_your_brief.what_are_the_benefits_of_a_brief.title') }}</h3>
            <ul>
              <li>{{ $t('writing_your_brief.what_are_the_benefits_of_a_brief.point1') }}</li>
              <li>{{ $t('writing_your_brief.what_are_the_benefits_of_a_brief.point2') }}</li>
              <li>{{ $t('writing_your_brief.what_are_the_benefits_of_a_brief.point3') }}</li>
              <li>{{ $t('writing_your_brief.what_are_the_benefits_of_a_brief.point4') }}</li>
            </ul>
          </b-col>
        </div>
      </div>

      <div class="d-flex flex-column flex-md-row bg-navy text-white p-5">
        <div class="container-fluid">
          <h3 class="text-left font-weight-bold w-100">{{ $t('writing_your_brief.example.design_brief.title') }}</h3>
          <p>{{ $t('writing_your_brief.example.design_brief.p1') }}</p>
          <div v-for="index in 10" :key="index">
              <p class="font-weight-bold text-uppercase mt-4 mb-1">{{ $t('writing_your_brief.example.design_brief.layout.question' + index) }}</p>
              <p>{{ $t('writing_your_brief.example.design_brief.layout.answer' + index) }}</p>
          </div>
        </div>

        <hr class="bg-white w-100 d-sm-none" />

        <div  class="container-fluid">
          <h3 class="text-left font-weight-bold w-100">{{ $t('writing_your_brief.example.creative_brief.title') }}</h3>
          <p>{{ $t('writing_your_brief.example.creative_brief.p1') }}</p>
          <div v-for="index in 15" :key="index">
            <p class="font-weight-bold text-uppercase  mt-4 mb-1">{{ $t('writing_your_brief.example.creative_brief.layout.question' + index) }}</p>
            <p>{{ $t('writing_your_brief.example.creative_brief.layout.answer' + index) }}</p>
            <p v-if="$te('writing_your_brief.example.creative_brief.layout.answer' + index + '_part2')"><i>{{ $t('writing_your_brief.example.creative_brief.layout.answer' + index + '_part2') }}</i></p>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-3">
        <div class="d-flex flex-row pt-0 pt-md-3 mt-0 mb-0 mb-md-3">
          <b-col order-md="2" class="text-center col-12 col-md-4 p-0 overflow-hidden">
            <img class="rounded-lg img-small" :src="require('@/assets/WritingYourBrief/in-summary.jpg')" />
          </b-col>
          <b-col class="col-12 col-md-8 d-flex flex-column align-items-start pl-3 pl-md-5 mt-4 m-md-0 px-4 px-md-0">
            <h3 class="text-left font-weight-bold w-100">{{ $t('writing_your_brief.in_summary.title') }}</h3>
            <p>{{ $t('writing_your_brief.in_summary.p1') }}</p>
            <p>{{ $t('writing_your_brief.in_summary.p2') }}</p>
            <p>{{ $t('writing_your_brief.in_summary.p3') }}</p>
          </b-col>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HeroCurved from '../components/Global/HeroCurved.vue'
import config from '../config'

export default {
  name: 'WritingYourBrief',
  components: {
    HeroCurved
  },
  metaInfo () {
    return {
      title: 'How to write an accurate creative brief.',
      description: 'Writing a detailed and accurate brief for freelancers doesn’t have to be hard. Here’s our guide!',
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'How to write an accurate creative brief.'
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://images.anytask.com/image/upload/b_rgb:ffffff,c_lpad,co_rgb:ffffff,f_jpg,h_630,w_1200/urbiknsbdw7r5mpntg5w.jpg'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Writing a detailed and accurate brief for freelancers doesn’t have to be hard. Here’s our guide!'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.protocol + '//' + window.location.host + '/writing-your-brief'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: config.anytasks_name
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  background-color: white !important;
}

ul {
  list-style-type: disc !important;
  li {
    list-style-type: disc !important;
    margin-bottom: 0.5em;
  }
}

@include sm-up {
  .img-small {
    width: 275px;
  }
}

.hero {
  background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25) ), url('~@/assets/WritingYourBrief/Banner/how-to-write-a-great-brief-banner-mobile.jpg');
  @include sm-up {
    background-image: url('~@/assets/WritingYourBrief/Banner/how-to-write-a-great-brief-banner-small.jpg');
  }
  @include md-up {
    background-image: url('~@/assets/WritingYourBrief/Banner/how-to-write-a-great-brief-banner-medium.jpg');
  }
  @include lg-up {
    background-image: url('~@/assets/WritingYourBrief/Banner/how-to-write-a-great-brief-banner-large.jpg');
  }
}
</style>
